export default {
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
  "quicklinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick links"])},
  "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional"])},
  "privacypolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
  "home": {
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find your perfect apartment"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Properties"])},
    "newOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New offers"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What we offer"])},
    "brokerage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real estate brokerage"])},
    "brokerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer high-quality brokerage services for the purchase and sale of both residential and commercial real estate. We fully represent the interests of our clients and guarantee that we are only guided by the client's goals and benefits."])},
    "consult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulting"])},
    "consultText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We consult on the purchase and sale of real estate, as well as on the development of real estate projects. We provide a full range of services for the purchase and sale of real estate"])},
    "staging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home staging"])},
    "stagingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home staging, i.e., home furnishing before sale and rent, is a marketing technique to present the property for sale in the best possible way, increase the interest of potential clients in the object and speed up the sale or rent."])},
    "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["room"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rooms"])},
    "floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["floor"])},
    "noinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No information"])},
    "lookmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Look more"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mo."])},
    "alloffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All offers"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
  },
  "modal": {
    "h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, leave your data, so we can contact you"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your phone number"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  },
  "search": {
    "properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Properties"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms"])},
    "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "nothingHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing here"])},
    "nothingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sorry, but we could not find anything for your search. Please try again."])},
    "flat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flat"])},
    "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House"])},
    "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "commercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter..."])}
  },
  "policy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
    "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Privacy Policy serves the purpose of protecting the privacy of Kodula OÜ’s former, current and future clients."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on the objective, we will outline how we collect, store, use, communicate and publish customer data."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All our principles are in accordance with the laws of the Republic of Estonia and European Union legislation."])},
    "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection and use of personal data"])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As personal data, we treat data that is voluntarily provided by a person and confirmed by written consent. Personal information may include a name, email address, phone number, address, and other data provided by customer to Kodula OÜ."])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use personal data only for the intended purpose that is forwarding newsleNers, providing market reviews and, if necessary, sharing other information related to the activities of Kodula OÜ."])},
    "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodula OÜ does not transmit, distribute, use or modify personal data entrusted to us other than in the prescribed manner, with the exception of the agreement with the client, or if the need for disclosure of information follows from the legislation of the Republic of Estonia."])},
    "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodula OÜ also collects data for contracts relating to the provision of real estate, but does not publish, distribute, transmit or use them for non-intended purposes (except for see Section 2.3)"])},
    "h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions of the privacy policy"])},
    "p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By submitting data to us, you acknowledge and agree to our policies and conditions."])},
    "p9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodula OÜ reserves the right to change the general terms and conditions of the Privacy Policy if necessary."])}
  },
  "aboutPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to my website!"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have been active in the real estate world since 2012, having previously acquired knowledge and work experience in both local and international companies in the field of sales and transport."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I started as an real estate agent in a real estate agency, where I got the boost to my real estate career thanks to my valued colleagues. I believe that the profession of a real estate agent is not just a job, but a way of life and it is the communication with different people that adds to the excitement – every day is different."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am positive about life, conscientious and determined. The will to act radiates from my eyes! I am a curious soul looking for constant development and new excitement. I consider it a priority to me to offer my clients a high-quality representation service through a professional attitude and personal approach – each client is unique and each object is different, it also requires a solution that meets their needs. In addition, I value pleasant customer service and always do my best to ensure that my clients only have the best experience."])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In my work, I am a neutral party who helps to see the whole, going through all the important points with the client, leading to the right and necessary decisions in the real estate sales process. As a real estate agent, I constantly participate in various trainings to improve my knowledge and skills to achieve the maximum result for my client."])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In addition to Estonian, I also communicate in Russian, German and English."])},
    "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The best recognition for the work done are satisfied and happy clients who also recommend my service to their friends and acquaintances."])},
    "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you need help with real estate, feel free to contact, let's talk more!"])}
  }
}