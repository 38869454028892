export default {
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Võtke ühendust"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meist"])},
  "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privaatsus"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktid"])},
  "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juriidiline"])},
  "quicklinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiirlink"])},
  "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisainfo"])},
  "privacypolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privaatsuspoliitika"])},
  "home": {
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leia parim pakkumine"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsing"])},
    "properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakkumised"])},
    "newOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uued pakkumised"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teenused"])},
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mida pakume"])},
    "brokerage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinnisvaravahendus"])},
    "brokerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakume kvaliteetset vahendusteenust nii elukondliku kui ärikinnisvara ostmisel kui ka müümisel. Esindame täielikult oma klientide huve ning garanteerime, et lähtume ainult kliendi eesmärkidest ja kasust."])},
    "consult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nõustamine"])},
    "consultText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anname endast parima, et olla Sinu jaoks kindel koostööpartner kõigis kinnisvaraga seotud tehingutes."])},
    "staging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügi- ja üürieelne kodusisustus"])},
    "stagingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home staging ehk müügi- ja üürieelne kodusisustus on turundustehnika, et esitleda müüdavat kinnisvara parimal viisil, suurendada potentsiaalsete klientide huvi objekti vastu ning kiirendada müüki või üürileandmist."])},
    "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tubade arv"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tubade arv"])},
    "floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["korrus"])},
    "noinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei leitud"])},
    "lookmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaata veel"])},
    "flat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korter"])},
    "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maja"])},
    "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maa"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Äripind"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kuu"])},
    "alloffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kõik pakkumised"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jah"])}
  },
  "policy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRIVAATSUSPOLIITIKA"])},
    "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üldinfo"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käesolev privaatsuspoliitika teenib eesmärki kaitsta Kodula OÜ endiste, praeguste ja tulevaste klientide privaatsust."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eesmärgist lähtuvalt toome välja punktid, kuidas me kliendi andmeid kogume, talletame, kasutame, edastame ja avaldame."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kõik meie põhimõNed on kooskõlas Eesti Vabariigi seaduste ja Euroopa Liidu õigusaktidega."])},
    "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isikuandmete kogumine ja kasutamine"])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isikuandmetena käsitleme andmeid, mis on isiku poolt antud vabatahtlikult ning kinnitatud kirjaliku nõusolekuga. Isikuandmed võivad sisaldada nime, emailiaadressi, telefoninumbrit, aadressi ja muid kliendi poolt Kodula’le edastatud andmeid."])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isikuandmeid kasutame vaid selleks eNenähtud eesmärgil ehk uudiskirjade edastamiseks, turuülevaadete andmiseks ning vajadusel muu Kodula OÜ tegevusega seotud info jagamiseks."])},
    "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodula OÜ ei edasta, levita, kasuta ega muuda mingil muul kui eNenähtud viisil meile usaldatud isikuandmeid, välja arvatud vastava kokkuleppe puhul kliendiga või kui info avaldamise vajadus tuleneb Eesti Vabariigi seadusandlusest."])},
    "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodula OÜ kogub andmeid ka kinnisvara vahendamisega seotud lepingute tarbeks, kuid ei avalda, levita, edasta ega kasuta neid miNe eNenähtud eesmärkidel (v.a. vt punkt 2.3)"])},
    "h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privaatsuspoliitika tingimused ja muudatused"])},
    "p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meile andmeid edastades kinnitate, et olete tutvunud meie põhimõtete ja tingimustega ning nendega nõustunud."])},
    "p9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodula OÜ jätab endale õiguse vajadusel muuta privaatsuspoliitika üldtingimusi."])}
  },
  "modal": {
    "h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Võtke ühendust"])},
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui teil on küsimusi või soovite lisainfot, siis võtke meiega ühendust."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage oma number"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saada"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulge"])}
  },
  "search": {
    "properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakkumised"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kõik"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsing"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüüp"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tubade arv"])},
    "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pindala"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hind"])},
    "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üür"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müük"])},
    "nothingHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole midagi siin"])},
    "nothingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kahjuks ei leidnud me midagi, mida otsid. Proovi uuesti."])},
    "flat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korter"])},
    "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maja"])},
    "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maa"])},
    "commercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Äripind"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alates"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuni"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage"])}
  },
  "aboutPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tere tulemast minu kodulehele!"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olen tegutsenud kinnisvaramaailmas 2012 aastast alates. Olles eelnevalt omandanud teadmisi ja töökogemusi nii kohalikes kui ka rahvusvahelises ettevõtetes müügitöö ning transpordivaldkonnas."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alustasin maaklerina kinnisvarabüroos, kust sain tänu hinnatud kolleegidele stardi oma kinnisvarakarjäärile. Usun, et kinnisvaramaakleri amet ei ole pelgalt töö vaid on elustiil ja põnevust lisab just suhtlemine erinevate inimestega - iga päev on eelmisest erinev."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Olen positiivse ellusuhtumisega, kohusetundlik ja sihikindel. Minu silmist kiirgab tegutsemistahe! Ma olen üks uudishimulik, pidevat arengut ja uusi põnevusi otsiv hing. Pean enda jaoks esmatähtsaks pakkuda oma klientidele kvaliteetset esindusteenust läbi professionaalse suhtumise ja personaalse lähenemise - iga klient on ainulaadne ning iga objekt erinev, see eeldab ka vajadustele vastavat lahendust. Lisaks hindan ma kõrgelt meeldivat klienditeenindust ja annan alati endast kõik, et minu kliendid saaksid ainult parima kogemuse osaliseks."])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oma töös olen ma neutraalne osapool, kes aitab näha tervikut, käies kliendiga koos läbi kõik tähtsad punktid, juhatades õigete ja vajalike otsusteni kinnisvara müügiprotsessis. Maaklerina läbin pidevalt erinevaid koolitusi, et täiendada oma teadmisi, oskuseid ning saavutada oma kliendile maksimaalseim tulemus."])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisaks eesti keelele, suhtlen veel vene, saksa ja inglise keeles."])},
    "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parim tunnustus tehtud töö eest on rahulolevad ja õnnelikud kliendid, kes minu teenust ka oma sõpradele ja tuttavatele soovitavad."])},
    "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui vajad kinnisvaraalaselt abi siis võta julgelt ühendust, räägime lähemalt!"])}
  }
}